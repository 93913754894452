import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints, mode } from '@chakra-ui/theme-tools';

import '@fontsource/josefin-sans'; // Defaults to weight 400.
import '@fontsource/heebo';

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const breakpoints = createBreakpoints({
  sm: '40em',
  md: '52em',
  lg: '64em',
  xl: '80em',
});
const styles = {
  global: props => ({
    body: {
      color: mode('#1C1B1B', 'whiteAlpha.900')(props),
      bg: mode('gray.100', '#1C1B1B')(props),
    },
  }),
};

const myTheme = extendTheme({
  colors: {
    dfcone: {
      900: '#810A46',
      800: '#A6114D',
      700: '#BA1651',
      600: '#D01955',
      500: '#E01D57', // main pink
      400: '#E53E6F',
      300: '#EB5F88',
      200: '#F18CAA',
      100: '#F6B9CB',
      50: '#FBE3EA',
    },
    dfctwo: {
      900: '#2A6913',
      800: '#4F8B25',
      700: '#629F2E',
      600: '#77B338',
      500: '#86C340', // main green
      400: '#98CC5E',
      300: '#ABD57B',
      200: '#C3E1A1',
      100: '#DBEDC6',
      50: '#F1F8E8',
    },
    dfcthree: {
      900: '#005B99',
      800: '#077BBB',
      700: '#009FE2',
      600: '#4193d3',
      500: '#02ADF1', // main blue
      400: '#2CBAF3',
      300: '#52C6F4',
      200: '#82D6F7',
      100: '#B4E6FA',
      50: '#E1F6FD ',
    },
    dfcfour: {
      900: '#D36239',
      800: '#DE7841',
      700: '#E68545',
      600: '#EE9349 ',
      500: '#F49D4D',
      400: '#F7AA58', // main orange
      300: '#F9B96F',
      200: '#FBCD95',
      100: '#FCE0BE',
      50: '#FEF3E5',
    },
    dfcfive: {
      900: '#42256E',
      800: '#51317B',
      700: '#5A3685',
      600: '#653E8E',
      500: '#6C4494',
      400: '#815EA3', // main purple
      300: '#967AB3',
      200: '#B3ADC9',
      100: '#D1C6DE',
      50: '#EDE8F1',
    },
    dfcwhite: {
      900: '#3C3C3C',
      800: '#606060',
      700: '#818181',
      600: '#979797',
      500: '#C2C2C2',
      400: '#DEDEDE',
      300: '#F0F0F0',
      200: '#F5F5F5',
      100: '#FAFAFA',
      50: '#FFFFFF', //main white
    },
    dfcblack: {
      900: '#000000', //main black
      800: '#262626',
      700: '#434343',
      600: '#555555',
      500: '#787878',
      400: '#9D9D9D',
      300: '#C4C4C4',
      200: '#D9D9D9',
      100: '#E9E9E9',
      50: '#FFFFFF',
    },
  },
  fonts: {
    heading: 'Josefin Sans, sans-serif',
    body: 'Heebo, sans-serif',
    mono: 'Heebo, sans-serif',
  },
  breakpoints,
  styles,
  config,
});

export default myTheme;
