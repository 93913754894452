import {
  Box,
  Img,
  Stack,
  Heading,
} from '@chakra-ui/react'
import * as React from 'react'


export const Sponsors = (props) => {
  const { year, picture, alternative } = props
  return (
    <Box 
      as="section" 
      py="15"
    >
        <Stack
          justify="center"
          direction={{
            base: 'column',
            md: 'row',
          }}
          mt="1"
          mb="1"
          spacing="4"
        >
          <Box
            maxW={{
              base: 'xl',
              md: '7xl',
            }}
            mx="auto"
            px={{
              base: '6',
              md: '8',
            }}
          >
            <Heading 
              color="dfcfive.400"
              mb={2}
              fontSize={{ base: '5xl', md: '5xl', }}
            >
              {year} Sponsors
            </Heading>
            <Img
                alt={alternative}
                src={picture}
              />
          </Box>
        </Stack>
      </Box>
  )
}