import React from 'react';
import { Button } from '@chakra-ui/react';

export const PinkButton = ({ children, onClick, ...rest }) => {
    return (
        <Button
            {...rest}
            rounded={'xl'}
            size={'lg'}
            fontWeight={'normal'}
            bg="dfcone.500"
            color="dfcwhite.50"
            border="2px"
            variant="outline"
            transform="transform 0.3s ease"
            cursor="pointer"
            _hover={{
                bg: 'dfcone.200',
                color: 'dfcwhite.900',
                boxShadow: 'dark-lg',
                _disabled: {
                    bg: 'dfcone.50',
                    color: 'dfcwhite.900',
                    boxShadow: 'none'
                },
            }}
            px={6}
            onClick={onClick}
        >
            {children}
        </Button>
    );
};
