import { Container, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import BlogPostWithImage from './blogPostcard';

const SkeletonCardGrid = ({ loaded, cardData }) => (
  <SimpleGrid
    display="grid"
    gridTemplateColumns={{
      base: '1fr',
      sm: 'repeat(auto-fit, minmax(400px, 1fr))',
    }}
    gap="20px"
    justifyContent="center"
    alignContent={'center'}
  >
    {loaded ? (
      cardData.map((d, i) => {
        return (
          <BlogPostWithImage
            key={i}
            imgSrc={d.img}
            heading={d.heading}
            body={d.body}
            bottomText={d.bottomTextTest ? d.bottomTextTest[0].data.text : ''}
          />
        );
      })
    ) : (
      <></>
    )}
  </SimpleGrid>
);

export default SkeletonCardGrid;
