import {
  Box,
  Image,
  Center,
  Heading,
  Text,
  Stack,
  Avatar,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react';

const IMAGE =
  'https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80';

const BlogPostWithImage = ({
  heading,
  body,
  imgSrc = false,
  bottomText = false,
}) => {
  return (
    <Center py={6}>
      <Box
        role={'group'}
        p={6}
        maxW={{ base: '80vw', sm: '500px', md: '400px', lg: '500px' }}
        minW={{ base: '150px', lg: '200px' }}
        H="700px"
        w={'full'}
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'lg'}
        pos={'relative'}
        zIndex={1}
        _hover={{
          boxShadow: '3px 3px 20px rgba(0,0,0,.3)',
        }}
      >
        <Box
          mb={3}
          rounded={'lg'}
          mt={-12}
          pos={'relative'}
          height={'200px'}
          _after={{
            transition: 'all .3s ease',
            content: '""',
            w: 'full',
            h: 'full',
            pos: 'absolute',
            top: 5,
            left: 0,
            backgroundImage: `url(${imgSrc ?? IMAGE})`,
            filter: 'blur(15px)',
            zIndex: -1,
          }}
          _groupHover={{
            _after: {
              filter: 'blur(20px)',
            },
          }}
        >
          <Image
            rounded={'lg'}
            height={200}
            width={'full'}
            objectFit={'fill'}
            src={imgSrc ?? IMAGE}
          />
        </Box>
        <Stack height="400px" position="relative">
          {/* <Text
          color={'green.500'}
          textTransform={'uppercase'}
          fontWeight={800}
          fontSize={'sm'}
          letterSpacing={1.1}
        >
          Subtitle
        </Text> */}
          <Heading
            color={useColorModeValue('gray.700', 'white')}
            fontSize={'2xl'}
            margin={'20px auto 0px'}
            fontFamily={'body'}
          >
            {heading}
          </Heading>
          <Text
            color={'gray.500'}
            fontSize={'20px'}
            overflowY={'auto'}
            maxH={bottomText ? '250px' : 'full'}
          >
            {body}
          </Text>
          <div>
            {bottomText ? (
              <Text
                maxH={'90px'}
                // wordBreak="break-all"
                overflowY={'auto'}
                position="absolute"
                bottom="0"
                width={'100%'}
                color="blue"
                fontWeight="600"
                fontSize="1rem"
                _hover={{
                  textDecoration: 'underline',
                }}
                dangerouslySetInnerHTML={{ __html: bottomText }}
              />
            ) : (
              <></>
            )}
          </div>
        </Stack>
      </Box>
    </Center>
  );
};

export default BlogPostWithImage;
