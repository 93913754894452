import {
  Link,
  Image
} from '@chakra-ui/react'
import React from 'react'


export default function Logo() {
  return(
    <Link href={'/'}>
   <Image
    alt={'Downriver Foster Closet Logo'}
    maxH={{
      base: '60px',
      sm: '35px',
      lg: '75px',
    }} 
    src={
      'https://downriver-foster-closet.s3.us-east-2.amazonaws.com/dfc-nav-logo.png'
    }
  />
</Link>
  )
}