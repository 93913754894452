import { Box, Heading, useColorModeValue as mode } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { getEvents, getSponsors } from '../api/index.js';
import { EventSection } from '../components/events/eventSection.jsx';
import { Sponsors } from '../components/sponsor/sponsor.jsx';

export default function Event() {
  const [eventData, setEventData] = useState([]);
  const [sponsorData, setSponsorData] = useState([]);
  const [loaded, setLoaded] = useState(0);
  const [loaded2, setLoaded2] = useState(0);

  const getData = async () => {
    await getEvents().then(res => {
      setEventData(res.data.data.listEvents.data);
      setLoaded(1);
    });
    await getSponsors().then(res => {
      setSponsorData(res.data.data.listSponsors.data);
      setLoaded2(1);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box>
      <Heading
        color={'dfcone.500'}
        mb={2}
        fontSize={{ base: '5xl', md: '5xl' }}
      >
        Events
      </Heading>
      <Box
        as="section"
        bg={mode('dfctwo.200', 'dfctwo.200')}
        color="dfctwo.500"
      >
        {loaded ? (
          eventData.map((e, i) => (
            <EventSection
              title={e.title}
              date={e.date}
              eventUrl={e.eventUrl ?? false}
              startTime={e.startTime}
              endTime={e.endTime}
              alternative={e.altText}
              picture={e.picture}
            />
          ))
        ) : (
          <h1>Loading...</h1>
        )}
      </Box>
      <Box bg="dfctwo.500">
        {loaded2 ? (
          sponsorData.map((s, i) => (
            <Sponsors
              year={s.year}
              alternative={s.altText}
              picture={s.picture}
            />
          ))
        ) : (
          <h1>Loading...</h1>
        )}
      </Box>
    </Box>
  );
}
