import { 
  Box, 
  chakra, 
  Flex, 
  Text, 
  useColorModeValue as mode 
} from '@chakra-ui/react'
import * as React from 'react'
import { 
  ImQuotesLeft, 
  ImQuotesRight 
} from 'react-icons/im'

export const TestimonialSection = (props) => {
  const {  name, title, children } = props
  const accentColor = mode(`dfctwo.500`, `dfcthree.500`)
  return (
    <Flex direction="column">
      <Box
        flex="1"
        rounded="lg"
        bg={mode('white', 'gray.700')}
        boxShadow={{ base: 'none', sm: mode('lg', 'lg-dark') }}
        shadow="base"
        px="10"
        pt="8"
        pb="12"
        pos="relative"
        mb="10"
      >
         <Box>
          <Text fontWeight="bold" color={accentColor}>{name}</Text>
          <Text fontWeight="bold" color={accentColor}>{title}</Text>
        </Box>
        <Box as={ImQuotesLeft} color={accentColor} fontSize="3xl" />
        <Text mt="5" fontSize="lg" fontWeight="bold">
          {children}
        </Text>
        <Box as={ImQuotesRight} color={accentColor} fontSize="3xl" />
        <chakra.svg
          pos="absolute"
          bottom="-7"
          insetStart="3.5rem"
          w="10"
          viewBox="0 0 38 28"
          color={mode('white', 'gray.700')}
          style={{
            filter: 'drop-shadow(0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          }}
        >
          <path d="M0 28V0H38L0 28Z" fill="currentColor" />
        </chakra.svg>
      </Box>
    </Flex>
  )
}