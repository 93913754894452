import {
  Link,
  Text,
  Box,
  Stack,
  Heading,
  useColorModeValue as mode
} from '@chakra-ui/react'
import * as React from 'react'


export default function Closets() {
  return(
    <Box as="section" bg={mode('gray.100', 'gray.800')} pt="16" pb="24">
      <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
        <Heading>Closets Around Michigan</Heading>
        <Text>
          If one of these closets serves your area please don't hesitate to reach out to them, make financial donations or something of need to them. They are here to help you!
        </Text>
        <Stack>
          <Link
            href='https://www.facebook.com/MartinAreaResourceCenter/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Allegan County - Martin - Martin Area Resource Center
          </Link>
          <Link
            href='https://www.facebook.com/BradensClosetOtsego/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Allegan County - Otsego - Braden's Closet
          </Link>
          <Link
            href='https://www.facebook.com/FosterClosetofNorthernMichigan/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Alpena County - Apena - Foster Closet of Northern Michigan
          </Link>
          <Link
            href='https://fosterclosetofmichigan.org/arenac-county/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Arenac County - Omer - Arenac Foster Closet
          </Link>
          <Link
            href='https://www.facebook.com/hiscloset.mi/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Barry County - Middleville - HIS Closet
          </Link>
          <Link
            href='https://www.facebook.com/FosterClosetOfMichiganBayCounty' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Bay County - Bay City - Foster Closet of Michigan - Bay County
          </Link>
          <Link
            href='https://www.facebook.com/FosterClosetOfMichiganBerrienCounty/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Berrien County - Bridgman - Foster Closet of Michigan - Berrien County
          </Link>
          <Link
            href='https://www.facebook.com/fosterclosetbranchcounty' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Branch County - Coldwater - Foster Closet of Branch County
          </Link>
          <Link
            href='https://www.facebook.com/EdwardsburgCloset/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Cass County - Edwardsburg - Edwardsburg Community Clothes Closet
          </Link>
          <Link
            href='https://www.facebook.com/roacommoncrawfordfostercloset' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Crawford County - Roscommon - Roscommon/Crawford Foster Closet
          </Link>
          <Link
            href='https://www.facebook.com/UPFosterClosetofDeltaCounty/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Delta County - Escanaba - Upper Pennisula Foster Closet of Delta County
          </Link>
          <Link
            href='https://www.facebook.com/UPFosterClosetofDickinsonCountyInc/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Dickinson County - Iron Mountain - UP Foster Closet of Dickinson County
          </Link>
          <Link
            href='https://www.suitcases4kidsmi.org/contact-us' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Eaton County - Charlotte - Guardian Angels/Suitcases 4 Kids
          </Link>
          <Link
            href='https://www.facebook.com/FosterClosetOfMichiganGeneseeCountyBranch' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Genesee County - Clio - Foster Closet of Genesee County
          </Link>
          <Link
            href='https://www.gihn-mi.org/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Gratiot County - Saint Louis - Foster Closet of Gratiot County
          </Link>
          <Link
            href='https://www.facebook.com/FosterClosetOfIsabellaCounty/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Isabella County - Mount Pleasant - Foster Closet of Isabella County
          </Link>
          <Link
            href='https://www.birthbrite.org/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Jackson County - Birth Brite Foster Closet
          </Link>
          <Link
            href='http://www.familiesofpromise.org/closet-of-hope' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Kent County - Wyoming - Closet of Hope
          </Link>
          <Link
            href='https://www.facebook.com/FCOMLivingston/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Livingston County - Fowlerville - Livingston County County Kid's Closet
          </Link>
          <Link
            href='https://www.facebook.com/MacombFosterCloset/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Macomb County - Mount Clemens - Macomb Foster Closet
          </Link>
          <Link
            href='https://www.facebook.com/UPFosterClosetofMarquetteinc/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Marquette County - Marquette - UP Foster Closet of Marquette
          </Link>
          <Link
            href='https://www.facebook.com/1418-Alliance-479591485584997/about?ref=page_internal' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Mason County - Ludington - 14.18 Alliance
          </Link>
          <Link
            href='https://www.facebook.com/upfosterclosetofmenominee' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Menominee County  - Menominee - UP Foster Closet of Menominee County
          </Link>
          <Link
            href='https://midlandcountyfostercloset.com/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Midland - Midland County Foster Closet
          </Link>
          <Link
            href='https://www.facebook.com/FosterClosetofMichiganWexfordMissaukee/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Missaukee County - Cadillac - Foster Closet of  Wexford-Missaukee
          </Link>
          <Link
            href='http://fosterclosetofmichigan.org/monroe-county/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Monroe County - Newport - OS Foster Closet of Monroe County
          </Link>
          <Link
            href='https://www.facebook.com/kidsbelongfostersharingcloset/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Muskegon County - Muskegon/Norton Shores - Kids Belong Foster Sharing Closet
          </Link>
          <Link
            href='https://fosterclosetofmichigan.org/oakland-county/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Oakland County - Farmington Hills - Oakland County Foster Closet
          </Link>
          <Link
            href='https://www.facebook.com/mosaicfosteradopt/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Ottawa County - Zeeland - Mosaic Clothing Co-op
          </Link>
          <Link
            href='https://www.facebook.com/roacommoncrawfordfostercloset' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Roscommon County - Roscommon - Roscommon/Crawford Foster Closet
          </Link>
          <Link
            href='https://rootedsjc.com/?fbclid=IwAR2ueDBcy9vUyI70C45mzNN-pRCkrufzuRa2mq9wgF_5s5YNzkDmwr0q3y0' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            St. Joseph County - Centreville - Rooted of St. Joseph County
          </Link>
          <Link
            href='https://www.facebook.com/FosterClosetofMichiganThumbArea/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Tuscola County - Fostoria - Foster Closet of Michigan - Thumb Area Branches
          </Link>
          <Link
            href='https://www.michiganfostercarecloset.org/ann-arbor/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Washtenaw County - Ann Arbor - Michigan Foster Care Closet
          </Link>
          <Link
            href='https://www.facebook.com/FosterClosetofMichiganWesternWayneCountyBranch/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Wayne County - Livonia - Foster Closet of Wayne County
          </Link>
          <Link
            href='https://www.facebook.com/Plymouth-Community-Foster-Closet-103361289064145/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Wayne County - Plymouth - Plymouth Community Foster Closet
          </Link>
          <Link
            href='http://www.downriverfostercloset.org/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Wayne County - Southgate - Downriver Foster Closet
          </Link>
          <Link
            href='https://www.facebook.com/FosterClosetofMichiganWexfordMissaukee/' 
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            color="dfcthree.500"
          >
            Wexford County - Cadillac - Foster Closet of  Wexford-Missaukee
          </Link>
          <Text>
            If you're running a foster closet in Michigan that's not lister please send us your information 
            <Link 
              href='/contact'
              style={{ textDecoration: 'none' }}
              color="dfcone.500"  
            >
              {' '} HERE 
            </Link>
            .
          </Text>
        </Stack>
      </Box>
    </Box>
  )
}