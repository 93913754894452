export const HOME_PAGE_CONTENT = `
query landing ($name: String!) {
	  getLandingPage(
    where: {
      iteration: $name 
    }
  ) {
  	data {
      missionTitle
      missionText
      tempCounterMax
      tempCounterCurrent
    }
  }
}
`;

export const ABOUT_US_CONTENT = `
query aboutUs ($name: String!) {
	getAboutUs (
    where: {
      epoch: $name
    }
  ) {
    data {
      aboutUsSection {
        sectionItemTitle
        sectionItemText
        sectionItemImg
      }
    }
  }
}
`;
export const SUMMARIES_CONTENT = `
query getSummaries ($name: String!) {
	getSummaries (
    where: {
      page: $name
    }
  ) {
    data {
      richTopSummary
      richBottomSummary
    }
  }
}
`;

export const INFO_CONTENT = `
query infoCards ($pageId: String!) {
	listInfoCards (
    where :{
      pageId: $pageId
    }
  ) {
    data {
			heading
      subHeader   
      body
      img
      bottomTextTest
    }
  }
}
`;

export const TESTIMONIAL_CONTENT = `
{
  listTestimonials {
  	data {
      name
      subtitle
      body
      createdOn
    }
	}
}
`;
export const EVENTS_CONTENT = `
{
  listEvents {
    data {
      title
      date
      eventUrl
      startTime
      endTime
      altText
      picture
    }
  }
}
`;
export const SPONSORS_CONTENT = `
{
  listSponsors {
    data {
      title
      year
      altText
      picture
    }
  }
}
`;
