import React, { useState, useEffect } from 'react';
import { Container, Heading, Stack, Text } from '@chakra-ui/react';
import SkeletonCardGrid from '../blogPostCard/SkeletonCardGrid';

import { RichTextRenderer } from '@webiny/react-rich-text-renderer';

import { getSummaries, getInfoCards } from '../../api';

const Summary = ({ pageID }) => {
  const [cardData, setCardData] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const [summaries, setSummaries] = useState(0);
  const [loaded2, setLoaded2] = useState(0);

  const getCardData = async id => {
    return await getInfoCards(id).then(res => {
      setCardData(res.data.data.listInfoCards.data);
      setLoaded(1);
    });
  };

  const getSummaryText = async id => {
    return await getSummaries(id).then(res => {
      setSummaries(res.data.data.getSummaries.data);
      setLoaded2(1);
    });
  };

  useEffect(() => {
    getCardData(pageID);
    getSummaryText(pageID);
  }, []);

  return (
    <Container maxW={'7xl'} py={16} as={Stack} spacing={12}>
      <Stack spacing={0} align={'center'}>
        <Heading marginBottom="1rem">{pageID}</Heading>
        {loaded2 ? (
          <Text _a={{ color: 'blue' }} textAlign={'center'}>
            {summaries.richTopSummary ? (
              <RichTextRenderer data={summaries.richTopSummary} />
            ) : (
              <></>
            )}
          </Text>
        ) : (
          <></>
        )}
      </Stack>

      <SkeletonCardGrid loaded={loaded} cardData={cardData} />
      {loaded2 ? (
        <Stack spacing={0} align={'center'}>
          <Text>
            {summaries.richBottomSummary ? (
              <RichTextRenderer data={summaries.richBottomSummary} />
            ) : (
              <></>
            )}
          </Text>
        </Stack>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default Summary;
