import { React } from 'react';
import {
  Box,
  Stack,
  Heading,
  Text,
  Img,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { getAboutUs } from '../api';

export default function About() {
  const [aboutUs, setAboutUs] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const getContent = async () =>
    await getAboutUs('PROD1').then(res => {
      setAboutUs(res.data.data.getAboutUs.data.aboutUsSection);
    });

  useEffect(() => {
    getContent();
  }, []);
  useEffect(() => {
    setLoaded(true);
  }, [aboutUs]);

  const generateSection = (sectionTitle, sectionText, sectionImgLink, i) => {
    if (!(i % 2 === 0)) {
      return (
        <Box
          key={i}
          as="section"
          bg={mode('gray.100', 'gray.800')}
          pt="16"
          pb="24"
        >
          <Box
            maxW={{ base: 'xl', md: '7xl' }}
            mx="auto"
            px={{ base: '6', md: '8' }}
          >
            <Stack
              direction={{ base: 'column', lg: 'row' }}
              spacing={{ base: '3rem', lg: '2rem' }}
              mt="8"
              align={{ lg: 'center' }}
              justify="space-between"
            >
              <Box
                pos="relative"
                w={{ base: 'full', lg: '560px' }}
                h={{ base: 'auto', lg: '560px' }}
              >
                <Img
                  w="full"
                  pos="relative"
                  zIndex="1"
                  h={{ lg: '100%' }}
                  objectFit="cover"
                  src={sectionImgLink}
                />
              </Box>
              <Box flex="1" maxW={{ lg: '520px' }}>
                <Heading
                  as="h1"
                  size="3xl"
                  color={mode('blue.600', 'blue.300')}
                  mt="8"
                  fontWeight="extrabold"
                  letterSpacing="tight"
                >
                  {sectionTitle}
                </Heading>
                <Text
                  color={mode('gray.600', 'gray.400')}
                  mt="4"
                  fontSize="lg"
                  fontWeight="medium"
                >
                  {sectionText}
                </Text>
              </Box>
            </Stack>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box
          key={i}
          as="section"
          bg={mode('gray.50', 'gray.800')}
          pt="16"
          pb="24"
        >
          <Box
            maxW={{ base: 'xl', md: '7xl' }}
            mx="auto"
            px={{ base: '6', md: '8' }}
          >
            <Stack
              direction={{ base: 'column', lg: 'row' }}
              spacing={{ base: '3rem', lg: '2rem' }}
              mt="8"
              align={{ lg: 'center' }}
              justify="space-between"
            >
              <Box flex="1" maxW={{ lg: '520px' }}>
                <Heading
                  as="h1"
                  size="3xl"
                  color={mode('blue.600', 'blue.300')}
                  mt="8"
                  fontWeight="extrabold"
                  letterSpacing="tight"
                >
                  {sectionTitle}
                </Heading>
                <Text
                  color={mode('gray.600', 'gray.400')}
                  mt="4"
                  fontSize="lg"
                  fontWeight="medium"
                >
                  {sectionText}
                </Text>
              </Box>
              <Box
                pos="relative"
                w={{ base: 'full', lg: '560px' }}
                h={{ base: 'auto', lg: '560px' }}
              >
                <Img
                  w="full"
                  pos="relative"
                  zIndex="1"
                  h={{ lg: '100%' }}
                  objectFit="cover"
                  src={sectionImgLink}
                />
              </Box>
            </Stack>
          </Box>
        </Box>
      );
    }
  };

  return (
    <>
      {loaded ? (
        <>
          {aboutUs.map((s, i) =>
            generateSection(
              s.sectionItemTitle,
              s.sectionItemText,
              s.sectionItemImg,
              i
            )
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
