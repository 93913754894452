import {
  Box,
  SimpleGrid,
  Heading,
  Textarea,
  Container,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  Alert,
  AlertIcon,
  FormHelperText,
  FormErrorMessage,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { PinkButton } from '../components/buttons/pink';
import axios from 'axios';
import { TestimonialSection } from '../components/testimonial/testimonial.jsx';
import { getTestimonials } from '../api';
import { useEffect } from 'react';

const submitForm = () => {
  const headers = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const handleReset = () => {
    document.getElementById('name').value = '';
    document.getElementById('email').value = '';
    document.getElementById('message').value = '';
    document.getElementById('title').value = '';
  };

  axios
    .post(
      'https://mgcq49q48c.execute-api.us-east-2.amazonaws.com/beta/contact',
      {
        Name: document.getElementById('name').value,
        Email: document.getElementById('email').value,
        Title: document.getElementById('title').value,
        Testimonial: document.getElementById('message').value,
        Service: 'DFC',
      },
      headers
    )
    .then(res => {
      document.getElementById('success_alert').style.display = 'block';
      document.getElementById('error_alert').style.display = 'none';
      handleReset();
    })
    .catch(err => {
      console.error(err);
      document.getElementById('success_alert').style.display = 'none';
      document.getElementById('error_alert').style.display = 'block';
    });
};

const Testimonial = () => {
  //Setting State
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');

  const [testimonialData, setTestimonialData] = useState([]);
  const [loaded, setLoaded] = useState(0);

  //Disable button for empty fields
  const isInvalid = name === '' || email === '' || message === '';

  //Function calls for empty fields as a precaution
  const handleNameChange = e => setName(e.target.value);
  const isNameError = name === '';

  const handleEmailChange = e => setEmail(e.target.value);
  const isEmailError = email === '';

  const handleMessageChange = e => setMessage(e.target.value);
  const isMessageError = message === '';

  const handleTitleChange = e => setTitle(e.target.value);
  const isTitleError = title === '';

  const getData = async () => {
    return await getTestimonials().then(res => {
      setTestimonialData(res.data.data.listTestimonials.data);
      setLoaded(1);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box as="section" bg={mode('dfcwhite.300', 'gray.800')} py="16">
      <Heading
        color={'dfcone.500'}
        mb={2}
        fontSize={{ base: '5xl', md: '5xl' }}
      >
        Testimonials
      </Heading>
      <Box
        maxW={{
          base: 'xl',
          md: '7xl',
        }}
        mx="auto"
        px="4"
        pt="12"
        pb="8"
      >
        <SimpleGrid
          columns={{
            base: 1,
            md: 2,
          }}
          spacing="10"
        >
          {loaded ? (
            testimonialData.map((d, i) => (
              <TestimonialSection key={i} name={d.name} title={d.subtitle}>
                {d.body}
              </TestimonialSection>
            ))
          ) : (
            <h1>Loading</h1>
          )}
        </SimpleGrid>
      </Box>
      <Container
        maxW="lg"
        py={{ base: '12', md: '24' }}
        px={{ base: '0', sm: '8' }}
      >
        <Stack spacing="8">
          <Stack spacing="6">
            <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
              <Heading
                color={'dfcone.500'}
                mb={2}
                fontSize={{ base: '3xl', md: '3xl' }}
                textAlign={'center'}
              >
                Leave Us a Testomonial
              </Heading>
            </Stack>
          </Stack>
          <Box
            py={{ base: '0', sm: '8' }}
            px={{ base: '4', sm: '10' }}
            bg={mode('white', 'gray.700')}
            boxShadow={{ base: 'none', sm: mode('md', 'md-dark') }}
            borderRadius={{ base: 'none', sm: 'xl' }}
          >
            <Stack spacing="6">
              <Stack spacing="6">
                <FormControl isRequired>
                  <FormLabel htmlFor="name" color={'dfcblack.700'}>
                    Name
                  </FormLabel>
                  <Input
                    isInvalid={isNameError}
                    id="name"
                    type="text"
                    borderColor="dfctwo.500"
                    onChange={handleNameChange}
                  />
                  {!isNameError ? (
                    <FormHelperText color={'dfcblack.700'} textAlign={'left'}>
                      Enter your full name.
                    </FormHelperText>
                  ) : (
                    <FormErrorMessage color="red">
                      Name is required.
                    </FormErrorMessage>
                  )}
                  <FormLabel htmlFor="email" color={'dfcblack.700'} mt={'4'}>
                    Email
                  </FormLabel>
                  <Input
                    isInvalid={isEmailError}
                    id="email"
                    type="email"
                    borderColor="mkyftwo.500"
                    onChange={handleEmailChange}
                  />
                  {!isEmailError ? (
                    <FormHelperText color={'dfcblack.700'} textAlign={'left'}>
                      Enter the email incase we need to contact you.
                    </FormHelperText>
                  ) : (
                    <FormErrorMessage color={'red'}>
                      Email is required.
                    </FormErrorMessage>
                  )}
                  <FormLabel htmlFor="title" color={'dfcblack.700'} mt={'4'}>
                    Title
                  </FormLabel>
                  <Input
                    isInvalid={isTitleError}
                    id="title"
                    type="title"
                    borderColor="mkyftwo.500"
                    onChange={handleTitleChange}
                  />
                  {!isEmailError ? (
                    <FormHelperText color={'dfcblack.700'} textAlign={'left'}>
                      Enter the title for your testimonial.
                    </FormHelperText>
                  ) : (
                    <FormErrorMessage color={'red'}>
                      Title is required.
                    </FormErrorMessage>
                  )}
                  <FormLabel htmlFor="message" color={'dfcblack.700'} mt={'4'}>
                    Testimonial
                  </FormLabel>
                  <Textarea
                    isInvalid={isMessageError}
                    id="message"
                    borderColor="dfctwo.500"
                    onChange={handleMessageChange}
                  />
                  {!isMessageError ? (
                    <FormHelperText color={'dfcblack.700'} textAlign={'left'}>
                      Enter the testimonial you wish to send to Downriver Foster
                      Closet.
                    </FormHelperText>
                  ) : (
                    <FormErrorMessage color={'red'}>
                      Testimonial is required to proceed.
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Stack>
              <Stack spacing="2">
                <PinkButton
                  onSubmit
                  px={6}
                  mt={5}
                  onClick={() => submitForm()}
                  disabled={isInvalid}
                >
                  Submit
                </PinkButton>
                <Text fontSize="xs" color="red" mt="-3">
                  * You must fill in all information to submit!
                </Text>
                <Alert
                  status="error"
                  variant="top-accent"
                  id="error_alert"
                  style={{ display: 'none' }}
                >
                  <AlertIcon />
                  There was an error processing your request!
                </Alert>
                <Alert
                  status="success"
                  variant="top-accent"
                  id="success_alert"
                  style={{ display: 'none' }}
                >
                  <AlertIcon />
                  Your testimonial was sent successfully!
                </Alert>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default Testimonial;
