import {
  Box,
  Heading,
  Img,
  Link,
  Stack,
  Text,
  useColorModeValue as mode,
  useMediaQuery,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import * as React from 'react';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import { SmallCloseIcon } from '@chakra-ui/icons';

dayjs.extend(customParseFormat);

export const EventSection = props => {
  const { title, date, startTime, endTime, picture, alternative, eventUrl } =
    props;

  const [isLargerThan680] = useMediaQuery('(min-width: 680px)');

  return (
    <Box>
      <Box
        as="section"
        bg={mode('gray.50', 'gray.800')}
        color="dfctwo.500"
        py="7.5rem"
      >
        <Box
          maxW={{
            base: 'xl',
            md: '5xl',
          }}
          mx="auto"
          px={{
            base: '6',
            md: '8',
          }}
        >
          <Box textAlign="center">
            <Heading
              as="h1"
              size="3xl"
              fontWeight="extrabold"
              maxW="48rem"
              mx="auto"
              lineHeight="1.2"
              letterSpacing="tight"
            >
              {title}
            </Heading>
            <Box
              display="flex"
              gap="3px"
              justifyContent="center"
              alignContent="center"
              flexDirection={{
                base: 'column',
                sm: 'row',
              }}
            >
              <Text fontSize="xl" mt="2" maxW="xl">
                {dayjs(date).format('dddd, MMMM D, YYYY')}
                {' ' + dayjs(`${startTime}`, 'hh:mm:ss').format('h:mm A')}{' '}
                {'- ' + dayjs(`${endTime}`, 'HH:mm:ss').format('h:mm A')}
              </Text>
              {eventUrl ? (
                <>
                  {isLargerThan680 ? <SmallCloseIcon mt="3" mx="3" /> : <></>}
                  <Link
                    fontWeight={800}
                    href={eventUrl}
                    target="_blank"
                    fontSize="xl"
                    mt="2"
                    // maxW="xl"
                    // mx="auto"
                  >
                    View more about the Event
                  </Link>
                </>
              ) : (
                <></>
              )}
            </Box>
          </Box>
          <Stack
            justify="center"
            direction={{
              base: 'column',
              md: 'row',
            }}
            mt="10"
            mb="20"
            spacing="4"
          >
            <Box
              justify="center"
              className="group"
              cursor="pointer"
              position="relative"
              rounded="lg"
              overflow="hidden"
            >
              <Img alt={alternative} src={picture} />
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
